<template>
  <div class="listForFollow">
    <div class="total">{{ $t('共') }} {{ totalCount }} {{ $t('条数据') }}</div>
    <List ref="list" :fetchApi="fetchApi" class="task-manage-list">
      <template v-slot:default="{ item, index }">
        <Card :dataSource="item" @click.native="toDetails(item)"/>
      </template>
    </List>
  </div>
</template>

<script>
import Card from './components/card.vue'
import List from '@/components/baseList/list.vue'
import loading from '@/utils/loading'
import { taskListFetch } from '@/services/taskManage.js'
import { mapGetters } from 'vuex'
export default {
  name: 'TaskManageFollow',
  components:{ Card, List },
  data() {
    return {
      totalCount: 0
    }
  },
  watch: {
  },
  created() {
  },
  methods:{
    fetchApi({ currentPage,pageSize }) {
      const { taskStatus, execTimeStart, execTimeEnd, roleCode, executorIds } = this.$route.query
      const params = {
        pageNum: currentPage,
        pageSize: pageSize,
        execTime: {
          start: execTimeStart,
          end: execTimeEnd,
        },
        taskStatus: [taskStatus],
        roleCode,
        executorIds,
        queryByExecutor: true
      }
      loading.showLoading()
      return taskListFetch(params).then((res) => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        res.dataList.forEach((item) => (item.checked = false))
        return res
      })
    },
    dateConfirm(){
      this.onRefresh()
    },
    onRefresh(){
      this.$refs?.list?.onRefresh()
    },
    toDetails(item){
      this.$router.push({
        path: '/clue-details',
        query: { id: item.leadId }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.listForFollow{
  .total{
    line-height: 28px;
    font-size: 14px;
    padding-left: 12px;
    padding: 10px 0 0 16px;
  }
}
</style>
